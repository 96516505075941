import React from "react"
import Layout from "../../components/Layout"
import RightColumn from "../../components/RightColumn"

export default class MicrobiologyTestingPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo19.jpg" sidebarImageAlt="B Travis" metaTitle="Microbiology Testing">
                    <div id="mainContent">
    <div class="header-one">Microbiology Testing</div>
<p><strong>WBA Analytical Laboratories</strong><strong>&trade;</strong> provide a wide array of microbiology testing applications for food products, pet foods, and sanitation monitoring for processing, food service, or retail locations.</p>
<ul>
<li>Quantitative Microbiology</li>
<li>Pathogen Detection</li>
<li>Shelf-life Studies</li>
<li>Molecular Typing</li>
<li>Live Production Serology</li>
<li>Consumer Relations Testing</li>
<li>Sanitation Monitoring</li></ul>
</div>

        <RightColumn />

        <br class="clearfloat" />

      

<h2 class="tableTitle">Alphabetical List of Routine Microbiological Testing</h2>
  
<table class="testTable">

    <tr class="darkGreyBack">
        <td class="column-1">
            Analysis
        </td>
        <td class="column-2">
            Analysis Methodology
        </td>
        <td class="column-3">
            ISO 17025 Accredited
        </td>
        <td class="column-4">
            Springdale
        </td>
        <td class="column-5">
            Wilkesboro
        </td>
    </tr>

    
            <tr>            
                <td class="column-1">              
                    Anaerobe APC
                </td>
                <td class="column-2">              
                    Mod of AOAC OMA 990.12
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    APC (Petrifilm)
                </td>
                <td class="column-2">              
                    AOAC 990.12, AOAC 986.33, AOAC 989.10
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    APC @20C (Petrifilm)
                </td>
                <td class="column-2">              
                    Mod of AOAC OMA 990.12
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Bacillus cereus
                </td>
                <td class="column-2">              
                    BAM Chapter 14:  Bacillus cereus; Compendium:  Chapter 32
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Bacillus Species
                </td>
                <td class="column-2">              
                    Internal Method
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Biochemical ID (Micro ID)
                </td>
                <td class="column-2">              
                    MLG Ch 8; AOAC OMA 989.12
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Biochemical ID (VITEK)
                </td>
                <td class="column-2">              
                    MLG Ch 8, AOAC 2012.02, 2011.17, RI080801 +
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Campylobacter (cefex)
                </td>
                <td class="column-2">              
                    USDA/FSIS Recommended Method
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Campylobacter (PCR)
                </td>
                <td class="column-2">              
                    AOAC RI 100201
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Campylobacter Enumeration
                </td>
                <td class="column-2">              
                    USDA/FSIS Recommended Method
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Clos. Perfringens
                </td>
                <td class="column-2">              
                    BAM Manual Ch 16, AOAC 976.30
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Coliforms (Petrifilm)
                </td>
                <td class="column-2">              
                    AOAC 991.14, AOAC 986.33, AOAC 989.10
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Coliforms, Total/Fecal
                </td>
                <td class="column-2">              
                    AOAC OMA 991.15
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Coliforms/E. coli (Petrifilm)
                </td>
                <td class="column-2">              
                    AOAC OMA 998.08, 991.14
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    E. coli O157 (BAX)
                </td>
                <td class="column-2">              
                    AOAC RI 050501
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    STEC (Real-time BAX)
                </td>
                <td class="column-2">              
                    AOAC RI 091301
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    E. coli O157:H7 (Real-time BAX)
                </td>
                <td class="column-2">              
                    AOAC RI 031002
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    E. coli O157 (VIDAS ECPT)
                </td>
                <td class="column-2">              
                    AOAC RI 060903
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    E. coli O157:H7 Confirmation
                </td>
                <td class="column-2">              
                    Modification of MLG 5.05:  Detection, Isolation and Identification of Escherichia coli O157:H7 from Meat Products
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Enterobacteriaceae (Petrifilm)
                </td>
                <td class="column-2">              
                    AOAC 2003.01
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Lactobacilli
                </td>
                <td class="column-2">              
                    Difco (MRS Agar); CCFRA 14.1
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Listeria Confirmation
                </td>
                <td class="column-2">              
                    MLG Ch 8
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Listeria MPN
                </td>
                <td class="column-2">              
                    BAM Manual Ch10
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Listeria sp. &amp; LM (BAX)
                </td>
                <td class="column-2">              
                    AOAC RI 030502 &amp; RI 070202
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Listeria sp. (VIDAS)
                </td>
                <td class="column-2">              
                    USDA/FSIS Recommended Method; AOAC 999.06
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Listeria sp. (BioRad)
                </td>
                <td class="column-2">              
                    AOAC RI 090701
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Pseudomonas
                </td>
                <td class="column-2">              
                    Oxoid Pseudomonas Agar w/CFC Sup, CCFRA 9.1
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Psychrotrophic APC
                </td>
                <td class="column-2">              
                    Mod of AOAC OMA 990.13
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Salmonella (BioRad)
                </td>
                <td class="column-2">              
                    AOAC RI 010803
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Salmonella (BAX)
                </td>
                <td class="column-2">              
                    AOAC RI 100201
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Salmonella (VIDAS SPT)
                </td>
                <td class="column-2">              
                    AOAC 2013.01
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Salmonella Confirmation
                </td>
                <td class="column-2">              
                    BAM Manual Ch 5; MLG Ch 5
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Salmonella MPN
                </td>
                <td class="column-2">              
                    Modification of MLG App 2.03
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Staph aureus (Petrifilm)
                </td>
                <td class="column-2">              
                    AOAC OMA 2003.07, 2003.11
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Total Chlorine mg/L
                </td>
                <td class="column-2">              
                    Hach Colorimeter
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Yeast and Mold (Petrifilm)
                </td>
                <td class="column-2">              
                    AOAC OMA 997.02
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Yeast and Mold (Rapid Petrifilm)
                </td>
                <td class="column-2">              
                    AOAC RI 121301
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
</table>

      </Layout>
    )
  }
}
